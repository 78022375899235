// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-primary: mat.define-palette(mat.$indigo-palette);
$client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.define-light-theme(
  (
    color: (
      primary: $client-primary,
      accent: $client-accent,
      warn: $client-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($client-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

.ltr {
  direction: ltr;
}

[dir='rtl'] .rtl\:rotateY-180 {
  transform: rotateY(180deg);
}

[dir='rtl'] .rtl\:rotateZ-180 {
  transform: rotateZ(180deg);
}

.mat-snack-bar-container {
  background-color: #ffffff;
}

.mat-simple-snack-bar-content {
  font-family: Montserrat, sans-serif;
}

[dir='rtl'] .mat-simple-snackbar {
  justify-content: flex-end;
}

[dir='rtl'] .rtl\:rotate-y-180 {
  transform: rotateY(180deg);
}

@media (max-width: 996px) {
  .cdk-global-overlay-wrapper {
    align-items: flex-end !important;
  }
}

.cdk-overlay-backdrop {
  @apply bg-black/[0.5];

  &.white {
    @apply bg-white/[0.1];
  }

  &.transparent {
    @apply bg-transparent;
  }
}

input.ng-invalid.ng-touched {
  @apply border-red;
}
